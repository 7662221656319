var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "512",
      "height": "512",
      "viewBox": "0 0 512 512"
    }
  }, [_c('g', {
    attrs: {
      "fill": "none",
      "fill-rule": "evenodd"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-width": "30",
      "d": "M146,111 C126.670034,111 111,126.670034 111,146 L111,366 C111,385.329966 126.670034,401 146,401 L366,401 C385.329966,401 401,385.329966 401,366 L401,180.710678 C401,171.428101 397.312511,162.525714 390.748737,155.961941 L356.038059,121.251263 C349.474286,114.687489 340.571899,111 331.289322,111 L146,111 Z"
    }
  }), _c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-width": "30",
      "d": "M177,110.933356 L177,162 C177,178.568542 190.431458,192 207,192 L290,192 C306.568542,192 320,178.568542 320,162 L320,110.933356 L320,110.933356 L177,110.933356 Z"
    }
  }), _c('circle', {
    attrs: {
      "cx": "256",
      "cy": "288",
      "r": "41",
      "stroke": "currentColor",
      "stroke-width": "30"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Settings",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', {
    attrs: {
      "title": "Ihre",
      "highlight": "Einstellungen"
    }
  }), _c('div', {
    staticClass: "Section"
  }, [_c('h3', [_vm._v("Profile")]), _c('div', [_c('div', {
    attrs: {
      "title": ""
    }
  }, [_c('Input', {
    attrs: {
      "isDisabled": true,
      "marginBottom": 4,
      "label": "Kundennummer",
      "value": _vm.profile.customerNumber,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    }
  }), _c('Input', {
    attrs: {
      "isDisabled": true,
      "marginBottom": 4,
      "label": "Registrierungsdatum",
      "value": _vm.format(_vm.profile.createdAt ? new Date(_vm.profile.createdAt) : new Date(), 'dd.MM.yyyy HH:mm'),
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    }
  }), _c('Input', {
    attrs: {
      "isDisabled": true,
      "marginBottom": 4,
      "label": "Account Status",
      "value": "Aktiviert",
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    }
  })], 1)])]), _c('div', {
    staticClass: "Section"
  }, [_c('h3', [_vm._v("Kontaktdaten")]), _c('div', [_c('div', {
    attrs: {
      "title": ""
    }
  }, [_c('Input', {
    attrs: {
      "keyName": "emailAddress",
      "label": "Email",
      "marginBottom": 4,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal',
      "value": _vm.profile.emailAddress
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "keyName": "phoneNumber",
      "label": "Telefonnummer",
      "marginBottom": 4,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal',
      "value": _vm.profile.phoneNumber
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  })], 1), _c('Button', {
    attrs: {
      "marginTop": 4,
      "size": "small",
      "isLoading": _vm.isSavingContactData
    },
    on: {
      "onClick": _vm.updateContactData
    }
  }, [_c('IconSave', {
    attrs: {
      "slot": "iconBefore",
      "width": "24px",
      "height": "24px"
    },
    slot: "iconBefore"
  }), _vm._v(" Kontaktdaten Speichern ")], 1)], 1)]), _c('div', {
    staticClass: "Section"
  }, [_c('h3', [_vm._v("Firmendaten")]), _c('div', [_c('div', {
    attrs: {
      "title": ""
    }
  }, [_c('Input', {
    attrs: {
      "label": "Steuernummer",
      "value": _vm.profile.vatNumber,
      "marginBottom": 4,
      "isDisabled": true,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "label": "Identificationsnummer",
      "value": _vm.profile.taxId,
      "marginBottom": 4,
      "isDisabled": true,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "label": "Firmenname",
      "value": _vm.profile.businessName,
      "marginBottom": 4,
      "isDisabled": true,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "label": "Straße",
      "value": _vm.profile.streetOne,
      "marginBottom": 4,
      "isDisabled": true,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "label": "Zusatz",
      "value": _vm.profile.streetTwo,
      "marginBottom": 4,
      "isDisabled": true,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "label": "PLZ",
      "value": _vm.profile.zipCode,
      "marginBottom": 4,
      "isDisabled": true,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  })], 1)])]), _c('div', {
    staticClass: "Section"
  }, [_c('h3', [_vm._v("Bankdaten")]), _c('div', [_c('div', {
    attrs: {
      "title": ""
    }
  }, [_c('Input', {
    attrs: {
      "label": "Inhaber",
      "value": _vm.profile.bankAccountOwner,
      "marginBottom": 4,
      "isDisabled": true,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "label": "Bank",
      "value": _vm.profile.bankName,
      "marginBottom": 4,
      "isDisabled": true,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "label": "IBAN",
      "value": _vm.profile.iban,
      "marginBottom": 4,
      "isDisabled": true,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "label": "Bic",
      "value": _vm.profile.bic,
      "marginBottom": 4,
      "isDisabled": true,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  })], 1)])]), _c('div', {
    staticClass: "Section"
  }, [_c('h3', [_vm._v("Einstellungen für die App")]), _c('div', [_c('div', [_c('Checkbox', {
    attrs: {
      "keyName": "theme",
      "name": "theme",
      "marginBottom": 4,
      "value": _vm.theme === 'dark' ? true : false,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onCheck": _vm.handleThemeChange
    }
  }, [_vm._v(" Dunkles Design ")])], 1)])]), _c('div', {
    staticClass: "Section"
  }, [_c('h3', [_vm._v("Einstellungen für die Fahrer-App")]), _c('div', [_c('Checkbox', {
    attrs: {
      "keyName": "showMultiMealDays",
      "name": "showMultiMealDays",
      "marginBottom": 4,
      "isChecked": _vm.showMultiMealDays,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onCheck": function onCheck(e) {
        return _vm.handleOnCheck({
          showMultiMealDays: e
        });
      }
    }
  }, [_vm._v("Mehrverpflegungstage in der PDF anzeigen (Arbeitszeiten) ")])], 1)]), _c('div', {
    staticClass: "Section"
  }, [_c('h3', [_vm._v("Passwort ändern")]), _c('div', [_c('div', {
    staticStyle: {
      "border": "none"
    },
    attrs: {
      "title": ""
    }
  }, [_c('Input', {
    attrs: {
      "type": "password",
      "keyName": "oldPassword",
      "label": "Altes Passwort",
      "marginBottom": 4,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "type": "password",
      "keyName": "password",
      "label": "Neues Passwort",
      "placeholder": "min. 8 Zeichen",
      "marginBottom": 4,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  }), _c('Input', {
    attrs: {
      "type": "password",
      "keyName": "newPasswordConfirmation",
      "label": "Passwort wiederholen",
      "marginBottom": 4,
      "direction": _vm.isMobile ? 'vertical' : 'horizontal'
    },
    on: {
      "onKeyPress": _vm.handleKeyPress
    }
  })], 1), _c('Button', {
    attrs: {
      "marginTop": 4,
      "size": "small"
    },
    on: {
      "onClick": _vm.updatePassword
    }
  }, [_c('IconSave', {
    attrs: {
      "slot": "iconBefore",
      "width": "24px",
      "height": "24px"
    },
    slot: "iconBefore"
  }), _vm._v(" Passwort Speichern ")], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
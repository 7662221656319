var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticClass: "Headline"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('span', [_vm._v(_vm._s(_vm.highlight))]), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
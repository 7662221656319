<template lang="html">
    <h1 class="Headline">
        {{ title }}
        <span>{{ highlight }}</span>
        <slot></slot>
    </h1>
</template>

<script>
export default {
    props: {
        title: [String, Number],
        highlight: [String, Number],
    },
};
</script>

<style lang="scss">
.Headline {
    color: var(--color-text-blue);
    margin-bottom: 55px;
    font-size: clamp(1.5rem, 3vw, 1.5rem);

    @media screen and (max-width: breakpoint(tabletPortrait)) {
        max-width: 100%;
    }

    h1 {
        font-size: clamp(1.5rem, 3vw, 1.5rem);
        color: var(--color-text-blue);
        font-weight: 700;
    }
    h2 {
        font-size: clamp(1.25rem, 3vw, 1.25rem);
        font-weight: 700;
        color: var(--color-text-blue);
        margin-top: 20px;
    }
    h3 {
        font-size: clamp(1.125rem, 3vw, 1.125rem);
        font-weight: 700;
        margin-bottom: -45px;
        color: var(--color-text-blue);
    }

    span {
        font-weight: 700;
    }

    .Currency {
        font-size: clamp(1rem, 3vw, 1rem);
        display: inline-block;
        margin-left: 5px;
    }

    .Description {
        margin-bottom: 0px;
        font-size: 18px;
    }
}
</style>

<template>
    <div class="Settings" :class="{ 'is-loading': isFetchingData }">
        <Headline title="Ihre" highlight="Einstellungen" />
        <div class="Section">
            <h3>Profile</h3>
            <div>
                <div title="">
                    <Input
                        :isDisabled="true"
                        :marginBottom="4"
                        label="Kundennummer"
                        :value="profile.customerNumber"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                    />
                    <Input
                        :isDisabled="true"
                        :marginBottom="4"
                        label="Registrierungsdatum"
                        :value="
                            format(
                                profile.createdAt ? new Date(profile.createdAt) : new Date(),
                                'dd.MM.yyyy HH:mm',
                            )
                        "
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                    />
                    <Input
                        :isDisabled="true"
                        :marginBottom="4"
                        label="Account Status"
                        value="Aktiviert"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                    />
                </div>
            </div>
        </div>
        <div class="Section">
            <h3>Kontaktdaten</h3>

            <div>
                <div title="">
                    <Input
                        keyName="emailAddress"
                        label="Email"
                        :marginBottom="4"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        :value="profile.emailAddress"
                        @onKeyPress="handleKeyPress"
                    />
                    <Input
                        keyName="phoneNumber"
                        label="Telefonnummer"
                        :marginBottom="4"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        :value="profile.phoneNumber"
                        @onKeyPress="handleKeyPress"
                    />
                </div>
                <Button
                    :marginTop="4"
                    @onClick="updateContactData"
                    size="small"
                    :isLoading="isSavingContactData"
                >
                    <IconSave slot="iconBefore" width="24px" height="24px" />
                    Kontaktdaten Speichern
                </Button>
            </div>
        </div>
        <div class="Section">
            <h3>Firmendaten</h3>

            <div>
                <div title="">
                    <Input
                        label="Steuernummer"
                        :value="profile.vatNumber"
                        :marginBottom="4"
                        :isDisabled="true"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                    <Input
                        label="Identificationsnummer"
                        :value="profile.taxId"
                        :marginBottom="4"
                        :isDisabled="true"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                    <Input
                        label="Firmenname"
                        :value="profile.businessName"
                        :marginBottom="4"
                        :isDisabled="true"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                    <Input
                        label="Straße"
                        :value="profile.streetOne"
                        :marginBottom="4"
                        :isDisabled="true"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                    <Input
                        label="Zusatz"
                        :value="profile.streetTwo"
                        :marginBottom="4"
                        :isDisabled="true"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                    <Input
                        label="PLZ"
                        :value="profile.zipCode"
                        :marginBottom="4"
                        :isDisabled="true"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                </div>
            </div>
        </div>
        <!-- <Button :marginBottom="10" @onClick="handleSave" size="large">
                <IconSave slot="iconBefore" width="24px" height="24px" />
                Firmendaten Speichern
            </Button> -->

        <div class="Section">
            <h3>Bankdaten</h3>

            <div>
                <div title="">
                    <Input
                        label="Inhaber"
                        :value="profile.bankAccountOwner"
                        :marginBottom="4"
                        :isDisabled="true"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                    <Input
                        label="Bank"
                        :value="profile.bankName"
                        :marginBottom="4"
                        :isDisabled="true"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                    <Input
                        label="IBAN"
                        :value="profile.iban"
                        :marginBottom="4"
                        :isDisabled="true"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />

                    <Input
                        label="Bic"
                        :value="profile.bic"
                        :marginBottom="4"
                        :isDisabled="true"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                </div>
            </div>
        </div>
        <!-- <Button :marginBottom="10" @onClick="handleSave" size="large">
                <IconSave slot="iconBefore" width="24px" height="24px" />
                Bankdaten Speichern
            </Button> -->
        <div class="Section">
            <h3>Einstellungen für die App</h3>

            <div>
                <div>
                    <Checkbox
                        keyName="theme"
                        name="theme"
                        :marginBottom="4"
                        :value="theme === 'dark' ? true : false"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onCheck="handleThemeChange"
                    >
                        Dunkles Design
                    </Checkbox>
                </div>
            </div>
        </div>

        <div class="Section">
            <h3>Einstellungen für die Fahrer-App</h3>

            <div>
                <Checkbox
                    keyName="showMultiMealDays"
                    name="showMultiMealDays"
                    :marginBottom="4"
                    :isChecked="showMultiMealDays"
                    :direction="isMobile ? 'vertical' : 'horizontal'"
                    @onCheck="e => handleOnCheck({ showMultiMealDays: e })"
                    >Mehrverpflegungstage in der PDF anzeigen (Arbeitszeiten)
                </Checkbox>
            </div>
        </div>

        <div class="Section">
            <h3>Passwort ändern</h3>

            <div>
                <div title="" style="border: none">
                    <Input
                        type="password"
                        keyName="oldPassword"
                        label="Altes Passwort"
                        :marginBottom="4"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                    <Input
                        type="password"
                        keyName="password"
                        label="Neues Passwort"
                        placeholder="min. 8 Zeichen"
                        :marginBottom="4"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                    <Input
                        type="password"
                        keyName="newPasswordConfirmation"
                        label="Passwort wiederholen"
                        :marginBottom="4"
                        :direction="isMobile ? 'vertical' : 'horizontal'"
                        @onKeyPress="handleKeyPress"
                    />
                </div>
                <Button :marginTop="4" @onClick="updatePassword" size="small">
                    <IconSave slot="iconBefore" width="24px" height="24px" />
                    Passwort Speichern
                </Button>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import Button from '@/components/widgets/Button';
// import Card from '@/components/Card';
import Checkbox from '@/components/widgets/Checkbox';
import IconSave from '@/components/icons/IconSave';
import Input from '@/components/widgets/Input';
import Headline from '@/components/Headline';
import axios from 'axios';
import { format } from 'date-fns';

export default {
    name: 'Settings',
    components: {
        Button,
        // Card,
        Checkbox,
        IconSave,
        Input,
        Headline,
    },
    data: () => {
        return {
            format,
            profile: {},
            newProfile: {},
            showMultiMealDays: false,
            showWorkingHours: false,
            theme: 'light',
            isFetchingData: true,
            isSavingContactData: false,
            isSavingPassword: false,
        };
    },
    computed: {
        isMobile() {
            return window.innerWidth < 500;
        },
    },
    created: function() {},
    methods: {
        handleKeyPress(value) {
            this.newProfile = { ...this.newProfile, ...value };
        },
        handleThemeChange(checked) {
            this.newProfile = { ...this.newProfile, theme: checked ? 'dark' : 'light' };
            this.theme = this.newProfile.theme;
            document.body.classList.remove('theme-light');
            document.body.classList.remove('theme-dark');
            document.body.classList.add(`theme-${this.newProfile.theme}`);
            localStorage.setItem('theme', this.newProfile.theme);
        },
        async handleOnCheck({ showMultiMealDays }) {
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/settings`;
                const result = await axios.put(
                    url,
                    {
                        hasWorkingTimeInAppEnabled: this.showWorkingHours,
                        hasAdditionalMealExpansesEnabled: showMultiMealDays,
                    },
                    {
                        withCredentials: true,
                    },
                );
                this.profile = result.data;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
        handleSave() {},
        async updateContactData() {
            try {
                this.isSavingContactData = true;
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/contact_data`;
                const result = await axios.put(
                    url,
                    {
                        emailAddress: this.newProfile.emailAddress ?? this.profile.emailAddress,
                        phoneNumber: this.newProfile.phoneNumber ?? this.profile.phoneNumber,
                    },
                    {
                        withCredentials: true,
                    },
                );
                this.profile = result.data;
            } catch (error) {
                this.isSavingContactData = false;
                this.$toasted.show('Bitte überprüfen Sie Ihre Eingaben.', { type: 'error' });
            } finally {
                this.isSavingContactData = false;
                this.isFetchingData = false;
            }
        },
        async updatePassword() {
            try {
                this.isSavingPassword = true;
                if (this.newProfile.password !== this.newProfile.newPasswordConfirmation) {
                    this.$toasted.show('Die Passwörter stimmen nicht überein.', { type: 'error' });
                    return;
                }

                if (!this.newProfile.password) {
                    this.$toasted.show('Bitte geben Sie ein neues Passwort ein.', {
                        type: 'error',
                    });
                    return;
                }

                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile/password`;
                const result = await axios.put(
                    url,
                    {
                        oldPassword: this.newProfile.oldPassword,
                        newPassword: this.newProfile.password,
                        newPasswordConfirmation: this.newProfile.password,
                    },
                    {
                        withCredentials: true,
                    },
                );
                this.$toasted.show('Passwort erfolgreich geändert.', { type: 'success' });
                this.newProfile = {
                    oldPassword: '',
                    password: '',
                    newPasswordConfirmation: '',
                };

                this.profile = result.data;
            } catch (error) {
                this.isSavingPassword = false;
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isSavingPassword = false;
                this.isFetchingData = false;
            }
        },
        async getProfile() {
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/profile`;
                const result = await axios.get(url, {
                    withCredentials: true,
                });
                this.$emit('onFinishLoading');
                this.profile = result.data;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
    },
    mounted() {
        this.getProfile();
        this.theme = localStorage.getItem('theme') || 'light';
    },
};
</script>

<style lang="scss">
.Settings {
    @extend %contentWrapper;
    @extend %page;
    margin-bottom: 80px;

    .InputWrap {
        margin-bottom: 10px !important;

        input {
            padding: 5px 10px;
            background-color: var(--color-white);
        }
        &:last-child {
            margin-bottom: 0px !important;
        }

        .Label {
            min-width: 200px;
            color: var(--color-text-black);
        }
    }

    .Section {
        padding: 0;
        margin-bottom: 0px;
        border: none;
        display: flex;
        gap: 10px;
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid var(--color-border);

        &:last-child {
            margin-bottom: 0px;
            border-bottom: none;
        }

        @media (max-width: 500px) {
            flex-direction: column;
        }

        > div {
            padding: 20px 0;
            width: 100%;
        }

        p,
        input,
        label {
            font-size: 14px !important;
        }

        h3 {
            margin-top: 20px;
            margin-right: 20px;
            width: 200px;
            font-size: 1rem;
            font-weight: 700;

            @media (max-width: 500px) {
                width: 100%;
            }
        }

        .Card {
            flex: 2;
        }
    }

    .Card {
        padding: 10px 20px !important;
        background: transparent;
        h3 {
            margin-top: 0;
        }
    }
}
</style>
